import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton, Theme,
  ThemeProvider,
  Typography
} from '@mui/material';
import { X } from '@phosphor-icons/react';
import { createRoot } from 'react-dom/client';

export function useConfirm(theme: Theme) {
  return (props: Omit<ConfirmModalProps, 'onClose'>) => {
    return new Promise<boolean | undefined>((resolve) => {
      const container = getContainer();
      const root = createRoot(container);

      document.body.style.setProperty('overflow', 'hidden');
      document.body.style.setProperty('padding-right', '15px');

      root.render(
        <ThemeProvider theme={theme}>
          <Modal
            {...props}
            onClose={(value?: boolean) => {
              resolve(value);
              const modal = document.getElementById('confirm-dialog');
              if (modal) {
                document.body.removeChild(modal);
              }
              if (container) {
                document.body.removeChild(container);
              }
              document.body.style.setProperty('overflow', null);
              document.body.style.setProperty('padding-right', null);
            }}
          />
        </ThemeProvider>
      );
    });
  };
}

export interface ConfirmModalProps {
  onClose: (value?: boolean) => void;
  okButtonTitle?: string;
  content: string | JSX.Element;
  cancelButtonTitle?: string;
  width?: string | number;
}

function Modal(props: ConfirmModalProps) {
  return (
    <Dialog open id={'confirm-dialog'} onClose={() => props.onClose(false)}>
      <>
        <Box
          sx={{
            display: 'flex',
            width: 1,
            justifyContent: 'flex-end',
            px: 2,
            pt: 2,
          }}
        >
          <IconButton onClick={() => props.onClose(false)}>
            <X size={24} color="#000" />
          </IconButton>
        </Box>

        <DialogContent sx={{ p: 3 }}>
          {typeof props.content === 'string' && (
            <Typography variant={'h4'} align={'center'}>
              {props.content}
            </Typography>
          )}
          {typeof props.content !== 'string' && props.content}
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Button
            variant={'outlined'}
            color={'primary'}
            data-test={'confirm-button-cancel'}
            onClick={() => props.onClose(false)}
          >
            {props.cancelButtonTitle || 'Cancel'}
          </Button>
          <Button
            variant={'contained'}
            color={'primary'}
            data-test={'confirm-button-ok'}
            onClick={() => props.onClose(true)}
          >
            {props.okButtonTitle || 'Delete'}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}

const getContainer = () => {
  let div: any;
  if (!document.getElementById('confirm-dialog-container')) {
    div = document.createElement('div');
    div.id = 'confirm-dialog-container';
    document.body.appendChild(div);
  }
  return document.getElementById('confirm-dialog-container') as HTMLElement;
};
